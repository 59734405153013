enifed('@ember/polyfills/index', ['exports', '@ember/polyfills/lib/assign', '@ember/polyfills/lib/weak_set', '@ember/deprecated-features', '@ember/polyfills/lib/merge'], function (exports, _assign, _weak_set, _deprecatedFeatures, _merge) {
  'use strict';

  exports.merge = exports._WeakSet = exports.assignPolyfill = exports.assign = undefined;
  Object.defineProperty(exports, 'assign', {
    enumerable: true,
    get: function () {
      return _assign.default;
    }
  });
  Object.defineProperty(exports, 'assignPolyfill', {
    enumerable: true,
    get: function () {
      return _assign.assign;
    }
  });
  Object.defineProperty(exports, '_WeakSet', {
    enumerable: true,
    get: function () {
      return _weak_set.default;
    }
  });

  var merge = _deprecatedFeatures.MERGE ? _merge.default : undefined;
  // Export `assignPolyfill` for testing
  exports.merge = merge;
});