enifed('@ember/polyfills/lib/weak_set', ['exports'], function (exports) {
    'use strict';

    exports.default = typeof WeakSet === 'function' ? WeakSet : function () {
        function WeakSetPolyFill() {

            this._map = new WeakMap();
        }

        WeakSetPolyFill.prototype.add = function add(val) {
            this._map.set(val, true);
            return this;
        };

        WeakSetPolyFill.prototype.delete = function _delete(val) {
            return this._map.delete(val);
        };

        WeakSetPolyFill.prototype.has = function has(val) {
            return this._map.has(val);
        };

        return WeakSetPolyFill;
    }();
});