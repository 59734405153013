enifed('@ember/engine/lib/engine-parent', ['exports', '@ember/-internals/utils'], function (exports, _utils) {
  'use strict';

  exports.getEngineParent = getEngineParent;
  exports.setEngineParent = setEngineParent;


  var ENGINE_PARENT = (0, _utils.symbol)('ENGINE_PARENT');

  /**
    `getEngineParent` retrieves an engine instance's parent instance.
  
    @method getEngineParent
    @param {EngineInstance} engine An engine instance.
    @return {EngineInstance} The parent engine instance.
    @for @ember/engine
    @static
    @private
  */
  /**
  @module @ember/engine
  */
  function getEngineParent(engine) {
    return engine[ENGINE_PARENT];
  }

  /**
    `setEngineParent` sets an engine instance's parent instance.
  
    @method setEngineParent
    @param {EngineInstance} engine An engine instance.
    @param {EngineInstance} parent The parent engine instance.
    @private
  */
  function setEngineParent(engine, parent) {
    engine[ENGINE_PARENT] = parent;
  }
});