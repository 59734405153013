enifed('@ember/modifier/index', ['exports', '@ember/-internals/glimmer'], function (exports, _glimmer) {
  'use strict';

  Object.defineProperty(exports, 'setModifierManager', {
    enumerable: true,
    get: function () {
      return _glimmer.setModifierManager;
    }
  });
  Object.defineProperty(exports, 'capabilties', {
    enumerable: true,
    get: function () {
      return _glimmer.modifierCapabilties;
    }
  });
});