enifed('@ember/application/index', ['exports', '@ember/-internals/owner', '@ember/application/lib/lazy_load', '@ember/application/lib/application'], function (exports, _owner, _lazy_load, _application) {
  'use strict';

  Object.defineProperty(exports, 'getOwner', {
    enumerable: true,
    get: function () {
      return _owner.getOwner;
    }
  });
  Object.defineProperty(exports, 'setOwner', {
    enumerable: true,
    get: function () {
      return _owner.setOwner;
    }
  });
  Object.defineProperty(exports, 'onLoad', {
    enumerable: true,
    get: function () {
      return _lazy_load.onLoad;
    }
  });
  Object.defineProperty(exports, 'runLoadHooks', {
    enumerable: true,
    get: function () {
      return _lazy_load.runLoadHooks;
    }
  });
  Object.defineProperty(exports, '_loaded', {
    enumerable: true,
    get: function () {
      return _lazy_load._loaded;
    }
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _application.default;
    }
  });
});