enifed("@ember/error/index", ["exports"], function (exports) {
  "use strict";

  exports.default = EmberError;
  /**
    A subclass of the JavaScript Error object for use in Ember.
  
    @class Error
    @namespace Ember
    @extends Error
    @constructor
    @public
  */
  function EmberError(message) {
    if (!(this instanceof EmberError)) {
      return new EmberError(message);
    }

    var error = Error.call(this, message);

    this.stack = error.stack;
    this.description = error.description;
    this.fileName = error.fileName;
    this.lineNumber = error.lineNumber;
    this.message = error.message;
    this.name = error.name;
    this.number = error.number;
    this.code = error.code;
  }

  EmberError.prototype = Object.create(Error.prototype);
  EmberError.prototype.constructor = EmberError;
});