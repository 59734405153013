define('ember-body-class/instance-initializers/body-class', ['exports', 'ember-body-class/util/bodyClass'], function (exports, _bodyClass) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    var config;

    if (instance.resolveRegistration) {
      // Ember 2.1+
      // http://emberjs.com/blog/2015/08/16/ember-2-1-beta-released.html#toc_registry-and-container-reform
      config = instance.resolveRegistration('config:environment');
    } else {
      config = instance.container.lookupFactory('config:environment');
    }

    // Default to true when not set
    var _includeRouteName = true;
    if (config['ember-body-class'] && config['ember-body-class'].includeRouteName === false) {
      _includeRouteName = false;
    }

    Ember.Route.reopen({
      classNames: [],
      bodyClasses: null,

      init: function init() {
        this._super.apply(this, arguments);

        Ember.set(this, 'bodyClasses', []);
      },
      _getRouteDepthClasses: function _getRouteDepthClasses() {
        var routeParts = this.get('routeName').split('.');
        var routeDepthClasses = routeParts.slice(0);
        var currentSelector = [];

        routeParts.forEach(function (part) {
          currentSelector.push(part);

          routeDepthClasses.push(currentSelector.join('-'));
        });

        return routeDepthClasses;
      },


      addClasses: Ember.on('activate', function () {
        this._setClassNamesOnBodyElement();
      }),

      _setClassNamesOnBodyElement: function _setClassNamesOnBodyElement() {
        var _this = this;

        var _Ember$getOwner$looku = Ember.getOwner(this).lookup('service:-document'),
            body = _Ember$getOwner$looku.body;

        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this.get(classes).forEach(function (klass) {
            (0, _bodyClass.addClass)(body, klass);
          });
        });

        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(function (depthClass) {
            (0, _bodyClass.addClass)(body, depthClass);
          });
        }
      },


      updateClasses: Ember.observer('bodyClasses.[]', 'classNames.[]', function () {
        var _this2 = this;

        var _Ember$getOwner$looku2 = Ember.getOwner(this).lookup('service:-document'),
            body = _Ember$getOwner$looku2.body;

        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this2.get(classes).forEach(function (klass) {
            (0, _bodyClass.removeClass)(body, klass);
          });
        });

        this._setClassNamesOnBodyElement();
      }),

      removeClasses: Ember.on('deactivate', function () {
        var _this3 = this;

        var _Ember$getOwner$looku3 = Ember.getOwner(this).lookup('service:-document'),
            body = _Ember$getOwner$looku3.body;

        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this3.get(classes).forEach(function (klass) {
            (0, _bodyClass.removeClass)(body, klass);
          });
        });

        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(function (depthClass) {
            (0, _bodyClass.removeClass)(body, depthClass);
          });
        }
      })
    });
  }

  exports.default = {
    name: 'body-class',
    initialize: initialize
  };
});