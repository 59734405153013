enifed('@ember/debug/index', ['exports', '@ember/debug/lib/warn', '@ember/debug/lib/deprecate', '@ember/debug/lib/testing', '@ember/-internals/browser-environment', '@ember/error'], function (exports, _warn2, _deprecate2, _testing, _browserEnvironment, _error) {
    'use strict';

    exports._warnIfUsingStrippedFeatureFlags = exports.getDebugFunction = exports.setDebugFunction = exports.deprecateFunc = exports.runInDebug = exports.debugFreeze = exports.debugSeal = exports.deprecate = exports.debug = exports.warn = exports.info = exports.assert = exports.setTesting = exports.isTesting = exports.registerDeprecationHandler = exports.registerWarnHandler = undefined;
    Object.defineProperty(exports, 'registerWarnHandler', {
        enumerable: true,
        get: function () {
            return _warn2.registerHandler;
        }
    });
    Object.defineProperty(exports, 'registerDeprecationHandler', {
        enumerable: true,
        get: function () {
            return _deprecate2.registerHandler;
        }
    });
    Object.defineProperty(exports, 'isTesting', {
        enumerable: true,
        get: function () {
            return _testing.isTesting;
        }
    });
    Object.defineProperty(exports, 'setTesting', {
        enumerable: true,
        get: function () {
            return _testing.setTesting;
        }
    });

    // These are the default production build versions:
    var noop = function () {};
    var assert = noop;
    var info = noop;
    var warn = noop;
    var debug = noop;
    var deprecate = noop;
    var debugSeal = noop;
    var debugFreeze = noop;
    var runInDebug = noop;
    var setDebugFunction = noop;
    var getDebugFunction = noop;
    var deprecateFunc = function () {
        return arguments[arguments.length - 1];
    };
    if (false /* DEBUG */) {
            exports.setDebugFunction = setDebugFunction = function (type, callback) {
                switch (type) {
                    case 'assert':
                        return exports.assert = assert = callback;
                    case 'info':
                        return exports.info = info = callback;
                    case 'warn':
                        return exports.warn = warn = callback;
                    case 'debug':
                        return exports.debug = debug = callback;
                    case 'deprecate':
                        return exports.deprecate = deprecate = callback;
                    case 'debugSeal':
                        return exports.debugSeal = debugSeal = callback;
                    case 'debugFreeze':
                        return exports.debugFreeze = debugFreeze = callback;
                    case 'runInDebug':
                        return exports.runInDebug = runInDebug = callback;
                    case 'deprecateFunc':
                        return exports.deprecateFunc = deprecateFunc = callback;
                }
            };
            exports.getDebugFunction = getDebugFunction = function (type) {
                switch (type) {
                    case 'assert':
                        return assert;
                    case 'info':
                        return info;
                    case 'warn':
                        return warn;
                    case 'debug':
                        return debug;
                    case 'deprecate':
                        return deprecate;
                    case 'debugSeal':
                        return debugSeal;
                    case 'debugFreeze':
                        return debugFreeze;
                    case 'runInDebug':
                        return runInDebug;
                    case 'deprecateFunc':
                        return deprecateFunc;
                }
            };
        }
    /**
    @module @ember/debug
    */
    if (false /* DEBUG */) {
            /**
              Verify that a certain expectation is met, or throw a exception otherwise.
                 This is useful for communicating assumptions in the code to other human
              readers as well as catching bugs that accidentally violates these
              expectations.
                 Assertions are removed from production builds, so they can be freely added
              for documentation and debugging purposes without worries of incuring any
              performance penalty. However, because of that, they should not be used for
              checks that could reasonably fail during normal usage. Furthermore, care
              should be taken to avoid accidentally relying on side-effects produced from
              evaluating the condition itself, since the code will not run in production.
                 ```javascript
              import { assert } from '@ember/debug';
                 // Test for truthiness
              assert('Must pass a string', typeof str === 'string');
                 // Fail unconditionally
              assert('This code path should never be run');
              ```
                 @method assert
              @static
              @for @ember/debug
              @param {String} description Describes the expectation. This will become the
                text of the Error thrown if the assertion fails.
              @param {Boolean} condition Must be truthy for the assertion to pass. If
                falsy, an exception will be thrown.
              @public
              @since 1.0.0
            */
            setDebugFunction('assert', function assert(desc, test) {
                if (!test) {
                    throw new _error.default('Assertion Failed: ' + desc);
                }
            });
            /**
              Display a debug notice.
                 Calls to this function are removed from production builds, so they can be
              freely added for documentation and debugging purposes without worries of
              incuring any performance penalty.
                 ```javascript
              import { debug } from '@ember/debug';
                 debug('I\'m a debug notice!');
              ```
                 @method debug
              @for @ember/debug
              @static
              @param {String} message A debug message to display.
              @public
            */
            setDebugFunction('debug', function debug(message) {
                /* eslint-disable no-console */
                if (console.debug) {
                    console.debug('DEBUG: ' + message);
                } else {
                    console.log('DEBUG: ' + message);
                }
                /* eslint-ensable no-console */
            });
            /**
              Display an info notice.
                 Calls to this function are removed from production builds, so they can be
              freely added for documentation and debugging purposes without worries of
              incuring any performance penalty.
                 @method info
              @private
            */
            setDebugFunction('info', function info() {
                var _console;

                (_console = console).info.apply(_console, arguments); /* eslint-disable-line no-console */
            });
            /**
             @module @ember/application
             @public
            */
            /**
              Alias an old, deprecated method with its new counterpart.
                 Display a deprecation warning with the provided message and a stack trace
              (Chrome and Firefox only) when the assigned method is called.
                 Calls to this function are removed from production builds, so they can be
              freely added for documentation and debugging purposes without worries of
              incuring any performance penalty.
                 ```javascript
              import { deprecateFunc } from '@ember/application/deprecations';
                 Ember.oldMethod = deprecateFunc('Please use the new, updated method', options, Ember.newMethod);
              ```
                 @method deprecateFunc
              @static
              @for @ember/application/deprecations
              @param {String} message A description of the deprecation.
              @param {Object} [options] The options object for `deprecate`.
              @param {Function} func The new function called to replace its deprecated counterpart.
              @return {Function} A new function that wraps the original function with a deprecation warning
              @private
            */
            setDebugFunction('deprecateFunc', function deprecateFunc() {
                for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                    args[_key] = arguments[_key];
                }

                if (args.length === 3) {
                    var message = args[0],
                        options = args[1],
                        func = args[2];

                    return function () {
                        deprecate(message, false, options);
                        return func.apply(this, arguments);
                    };
                } else {
                    var _message = args[0],
                        _func = args[1];

                    return function () {
                        deprecate(_message);
                        return _func.apply(this, arguments);
                    };
                }
            });
            /**
             @module @ember/debug
             @public
            */
            /**
              Run a function meant for debugging.
                 Calls to this function are removed from production builds, so they can be
              freely added for documentation and debugging purposes without worries of
              incuring any performance penalty.
                 ```javascript
              import Component from '@ember/component';
              import { runInDebug } from '@ember/debug';
                 runInDebug(() => {
                Component.reopen({
                  didInsertElement() {
                    console.log("I'm happy");
                  }
                });
              });
              ```
                 @method runInDebug
              @for @ember/debug
              @static
              @param {Function} func The function to be executed.
              @since 1.5.0
              @public
            */
            setDebugFunction('runInDebug', function runInDebug(func) {
                func();
            });
            setDebugFunction('debugSeal', function debugSeal(obj) {
                Object.seal(obj);
            });
            setDebugFunction('debugFreeze', function debugFreeze(obj) {
                Object.freeze(obj);
            });
            setDebugFunction('deprecate', _deprecate2.default);
            setDebugFunction('warn', _warn2.default);
        }
    var _warnIfUsingStrippedFeatureFlags = void 0;
    if (false /* DEBUG */ && !(0, _testing.isTesting)()) {
        if (typeof window !== 'undefined' && (_browserEnvironment.isFirefox || _browserEnvironment.isChrome) && window.addEventListener) {
            window.addEventListener('load', function () {
                if (document.documentElement && document.documentElement.dataset && !document.documentElement.dataset.emberExtension) {
                    var downloadURL = void 0;
                    if (_browserEnvironment.isChrome) {
                        downloadURL = 'https://chrome.google.com/webstore/detail/ember-inspector/bmdblncegkenkacieihfhpjfppoconhi';
                    } else if (_browserEnvironment.isFirefox) {
                        downloadURL = 'https://addons.mozilla.org/en-US/firefox/addon/ember-inspector/';
                    }
                    debug('For more advanced debugging, install the Ember Inspector from ' + downloadURL);
                }
            }, false);
        }
    }
    exports.assert = assert;
    exports.info = info;
    exports.warn = warn;
    exports.debug = debug;
    exports.deprecate = deprecate;
    exports.debugSeal = debugSeal;
    exports.debugFreeze = debugFreeze;
    exports.runInDebug = runInDebug;
    exports.deprecateFunc = deprecateFunc;
    exports.setDebugFunction = setDebugFunction;
    exports.getDebugFunction = getDebugFunction;
    exports._warnIfUsingStrippedFeatureFlags = _warnIfUsingStrippedFeatureFlags;
});