enifed("@ember/-internals/routing/lib/system/query_params", ["exports"], function (exports) {
    "use strict";

    var QueryParams = function QueryParams() {
        var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        this.isQueryParams = true;
        this.values = values;
    };

    exports.default = QueryParams;
});