define("ember-intl/-private/is-array-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(a, b) {
    if (!Ember.isArray(a) || !Ember.isArray(b)) {
      return false;
    }

    if (a === b) {
      return true;
    }

    return a.toString() === b.toString();
  }
});