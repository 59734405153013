define('ember-simple-auth-token/mixins/token-adapter', ['exports', 'ember-get-config', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberGetConfig, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Mixin.create(_dataAdapterMixin.default, {
    session: Ember.inject.service('session'),

    /**
      @method init
    */
    init: function init() {
      this._super.apply(this, arguments);
      var conf = _emberGetConfig.default['ember-simple-auth-token'] || {};
      this.tokenPropertyName = conf.tokenPropertyName || 'token';
      this.authorizationHeaderName = conf.authorizationHeaderName || 'Authorization';
      this.authorizationPrefix = conf.authorizationPrefix === '' ? '' : conf.authorizationPrefix || 'Bearer ';
    },


    /*
      Adds the `token` property from the session to the `authorizationHeaderName`:
    */
    headers: Ember.computed('session.data.authenticated', function () {
      var data = this.get('session.data.authenticated');
      var token = Ember.get(data, this.get('tokenPropertyName'));
      var prefix = this.get('authorizationPrefix');
      var header = this.get('authorizationHeaderName');

      if (this.get('session.isAuthenticated') && !Ember.isEmpty(token)) {
        return _defineProperty({}, header, '' + prefix + token);
      } else {
        return {};
      }
    })
  });
});